import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()
console.log(serializedTokens,"serializedTokens")
const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.ethax,
    earningToken: serializedTokens.ethax,
    contractAddress: {
      97: '0xc864FBF36F7C32303111a2693841dFf0B222c3CF',
      56: '0x1a8EE6994970F4967227cBBa3fB06ca2bbdD0aEc',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 0,
    isFinished: false,
  },
]

export default pools
