import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  ethax: new Token(
    MAINNET,
    '0x854f7cd3677737241e3eed0dc3d7f33dfaf72bc4',
    18,
    'ETHAX',
    'ETHAX Token',
    'https://www.ethax.io/images/logo_icon.png',
  ),
  wbnb: new Token(
    MAINNET,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://pancakeswap.finance/images/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png',
  ),
  cake: new Token(
    MAINNET,
    '0x854f7cd3677737241e3eed0dc3d7f33dfaf72bc4',
    18,
    'ETHAX',
    'ETHAX Token',
    'https://pancakeswap.finance/',
  ),
  busd: new Token(
    MAINNET,
    '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  tether: new Token(
    MAINNET,
    '0x55d398326f99059ff775485246999027b3197955',
    18,
    'Tether-USDT',
    'USDT',
    'https://www.paxos.com/busd/',
  )
}

export const testnetTokens = {
  wbnb: new Token(
    TESTNET,
    '0xBaE36c04b80FE01bb1611160B199fACB7E3CdC27',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  ethax: new Token(
    TESTNET,
    '0x854f7cd3677737241e3eed0dc3d7f33dfaf72bc4',
    18,
    'ETHAX',
    'ETHAX Token',
    'https://www.ethax.io/images/logo_icon.png',
  ),
  sum: new Token(
    MAINNET,
    '0x752B357e2c8704597eA9D0CAAd2571A449743860',
    18,
    'SUM',
    'SUMCOIN',
    'http://wsum.wealwin.com/sumcoin.png',
  ),
  cake: new Token(
    TESTNET,
    '0x0ae315ba1485a4106dc2d924066ba016f8b04840',
    18,
    'ETHAX',
    'WETHAX Token',
    'https://pancakeswap.finance/',
  ),
  co2: new Token(
    TESTNET,
    '0x96322336e6327a4d258026868175037fafdd1a1a',
    18,
    'CO2',
    'CO2',
    'https://pancakeswap.finance/',
  ),
  busd: new Token(
    TESTNET,
    '0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't

  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
