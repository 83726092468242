import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: AvenirNextRegular;
    src: url(./fonts/AvenirNextRegular.otf);
  }
  @font-face {
    font-family: AvenirNextBold;
    src: url(./fonts/AvenirNextBold.otf);
  }
  * {
    font-family: AvenirNextRegular;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    img {
      height: auto;
      max-width: 100%;
    }
  }
  a:hover {
    text-decoration: none;
  }
  nav a {
    text-transform: uppercase;
  }
  nav a:hover {
    background: transparent !important;
    color: #1fc7d4;
  }
  .buy_btn {
    text-transform: capitalize;
  }
  .buy_btn:hover {
    background-color: #03ffff !important;
    color: #000;
  }
  button:focus {
    outline: none !important;
  }
  .walletconnect-connect__button__text {
    font-size : 1vw !important;
  }
  .dnone {
    display:none;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: AvenirNextBold;
  }
  ::-webkit-scrollbar-thumb {
    background: #03ffff;
  }
  // div#homepage-hero {
  //   margin-top: 60px;
  // }
  .dash_subheading {
    font-size: 18px;
  }
  .farm_page_bg > div:first-child {
    background: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  }
  h2,
  h1 {
    text-transform: uppercase;
  }
  .whiteBox {
    background: rgb(255, 255, 255);
    border: 1px solid #03ffff;
    border-radius: 15px;
    padding: 30px;
    height: 100%;
  }
  .titleBox {
    margin-bottom: 25px;
  }
  .whiteBox h2 {
    font-size: 40px;
    font-family: AvenirNextBold;
    text-transform: uppercase;
  }
  .flexRow {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 22px;
  }
  .rowContent {
    display: flex;
    margin-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid4 {
    width: 40%;
    max-width: 452px;
    padding: 0 15px
  }
  .grid6 {
    width: 50%;
    max-width: 678px;
    padding: 0 15px
  }
  .grid8 {
    width: 60%;
    max-width: 700px;
    padding: 0 15px
  }
  .grid12 {
    width: 100%;
    max-width: 1152px;
    padding: 0 15px
  }
  .fsBox h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .fsBox {
    margin-bottom: 30px;
  }
  .listBox span {
    font-size: 20px;
    font-weight: 600
  }
  .listBox h3 {
    font-size: 20px;
    font-weight: 600
  }
  .whiteBox.earnBox.audit_box {
    padding: 20px;
  }
@media screen and (max-width: 991px) {
  .rowContent {
    flex-wrap: wrap;
  }
  .grid6,
  .grid4 {
    width: 100%;
    margin-bottom: 30px;
    max-width: 100%;
    padding: 0;
  }
  .grid4 {
    margin-top: -30px;
  }
  div#homepage-hero {
    display: block;
  }
  #homepage-hero h1 {
    font-size: 36px !important;
  }
  .whiteBox {
    padding: 15px;
  }
  .whiteBox h2 {
    font-size: 24px;
  }
  .whiteBox .flexRow.titleBox img {
    width: 80px;
    height: 80px;
  }
  .listBox span,
  .listBox h3 {
    font-size: 16px;
  }

}

`

export default GlobalStyle
