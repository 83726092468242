import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'ETHAX-BUSD LP',
    lpAddresses: {
      97: '0x9D1f8C941D4A0745FEf6D6cC02b0FEFC93d3e47f',
      56: '0xEbCD635a395e2ecE39A7583fe8b6792DD79f02cA',
    },
    token: serializedTokens.ethax,
    quoteToken: serializedTokens.busd,

  },
  {
    pid: 1,
    lpSymbol: 'ETHAX-BNB LP',
    lpAddresses: {
      97: '0x9D1f8C941D4A0745FEf6D6cC02b0FEFC93d3e47f',
      56: '0x1915ddEb50db91a50Ae9afad343fa75512fbf220',
    },
    token: serializedTokens.ethax,
    quoteToken: serializedTokens.wbnb,
  }

]

export default farms
